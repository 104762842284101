var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"container",staticClass:"py-0 px-0",attrs:{"fluid":""}},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":""}},[_c('v-tab',{attrs:{"to":{
                name: 'wo-bom-search',
            }}},[_vm._v(" SEARCH ")]),_c('v-tab',{attrs:{"to":{
                name: 'wo',
            }}},[_vm._v(" Work Orders ")]),_c('v-tab',{attrs:{"to":{
                name: 'closed-wo',
            }}},[_vm._v(" Closed Work Orders ")]),(_vm.markQualityRegisters)?_c('v-tab',{attrs:{"to":{
                name: 'quality-pending',
            }}},[_vm._v(" QA Pending ")]):_vm._e(),_c('v-tab',{attrs:{"to":{
                name: 'bom',
            }}},[_vm._v(" Bill Of Materials ")])],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"overflow-y-auto px-4 pt-4",style:(`height: ${_vm.height}px`)},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }